export class Constants {

  public static TITLE_INFO = 'INFO!';
  public static TITLE_ERROR = 'ERROR!';
  public static TITLE_WARNING = 'WARNING!';
  public static TITLE_SUCCESS = 'SUCCESS!';
  public static TITLE_OFFLINE = 'IS OFFLINE!';

  static Routing = class {
    public static LOGIN = { label: 'Login', path: 'login', routerLink: ['/login'] };
    public static DASHBOARD = { label: 'Dashboard', path: 'dashboard', routerLink: ['/dashboard'] };
    public static APPSTORE = { label: 'App Store', path: 'appstore', routerLink: ['/appstore'] };
    public static RESET_PASSWORD = { label: 'Reset Password', path: 'reset-password', routerLink: ['/reset-password'] };
    public static SET_PASSWORD = { label: 'Set Your Password', path: 'setpassword', routerLink: ['/setpassword'] };
    public static RESETPWD_COMPLETED = { label: 'Reset Password Completed', path: 'resetpwd-completed', routerLink: ['/resetpwd-completed'] };
    public static REGISTRATION = { label: 'User Registration', path: 'registration', routerLink: ['/registration'] };
    public static REGISTRATION_GUEST = { label: 'User Registration', path: 'registration-guest', routerLink: ['/registration-guest'] };
    public static REGISTRATION_PENDING = { label: 'Registration Pending', path: 'registration-pending', routerLink: ['/registration-pending'] };
    public static REGISTRATION_COMPLETED = { label: 'Registration Completed', path: 'registration-completed', routerLink: ['/registration-completed'] };
    public static DELETE_PROFILE = { label: 'Delete profile', path: 'delete-profile', routerLink: ['/delete-profile'] };
    public static NOT_FOUND = { label: 'Not_found', path: 'not-found', routerLink: ['/not-found'] };
  }

  static Auth = class {
    public static USER_KEY = "user";
    public static ENV_KEY = "environment";
  }

  static Storager = class {
    public static USER_KEY = "user";
  }

}
