import { Component } from '@angular/core';

@Component({
  selector: 'app-registration-pending',
  templateUrl: './registration-pending.component.html',
  styles: ``
})
export class RegistrationPendingComponent {

}
