export class UserCreationRequest {
    birthDate?: string;
    country?: string;
    language?: string;
    name?: string;
    surname?: string;
    hin?: string;
    username?: string;
    phoneNumber?: string;
}


