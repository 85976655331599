<div class="registration-completed" id="main">
    <app-header></app-header>
    <div class="main-body">
        <div class="d-flex justify-content-center">
            <div class="content">
                <div class="check-icon"></div>
                <div class="heading">
                    <h2>{{ 'registration_pending.title' | translate}}</h2>
                    <p [innerHTML]="'registration_pending.text' | translate"></p>
                </div>
            </div>
        </div>
    </div>
</div>
