import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Constants } from './_utility';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { RegistrationComponent } from './layout/registration/registration.component';
import { RegistrationCompletedComponent } from './layout/registration-completed/registration-completed.component';
import { AppStoreComponent } from './layout/app-store/app-store.component';
import { ResetPasswordComponent } from './layout/reset-password/reset-password.component';
import { ResetpwdCompletedComponent } from './layout/resetpwd-completed/resetpwd-completed.component';
import { DeleteProfileComponent } from './layout/delete-profile/delete-profile.component';
import { RegistrationGuestsComponent } from './layout/registration-guests/registration-guests.component';
import { RegistrationPendingComponent } from './layout/registration-pending/registration-pending.component';
import { SetPasswordComponent } from './layout/set-password/set-password.component';

const routes: Routes = [
  { path: '', redirectTo: '/appstore', pathMatch: 'full' },
  { path: Constants.Routing.REGISTRATION.path, component: RegistrationComponent },
  { path: Constants.Routing.REGISTRATION_GUEST.path, component: RegistrationGuestsComponent },
  { path: Constants.Routing.REGISTRATION_PENDING.path, component: RegistrationPendingComponent  },
  { path: Constants.Routing.REGISTRATION_COMPLETED.path, component: RegistrationCompletedComponent  },
  { path: Constants.Routing.APPSTORE.path, component: AppStoreComponent },
  { path: Constants.Routing.SET_PASSWORD.path, component: SetPasswordComponent },
  { path: Constants.Routing.RESET_PASSWORD.path, component: ResetPasswordComponent },
  { path: Constants.Routing.RESETPWD_COMPLETED.path, component: ResetpwdCompletedComponent },
  { path: Constants.Routing.DELETE_PROFILE.path, component: DeleteProfileComponent },
  { path: Constants.Routing.NOT_FOUND.path, component: NotFoundComponent },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
