import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment.dev';
import { SpinnerModule } from './_components/spinner/spinner.module';
import { SpinnerService } from './_components/spinner/spinner.service';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthService } from './_services';
import { AuthGuard, ErrorInterceptor, JwtInterceptor } from './_utility';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { RegistrationComponent } from './layout/registration/registration.component';
import { HeaderComponent } from './_components/header/header.component';


//primeng
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { RegistrationCompletedComponent } from './layout/registration-completed/registration-completed.component';
import { AppStoreComponent } from './layout/app-store/app-store.component';
import { ResetPasswordComponent } from './layout/reset-password/reset-password.component';
import { ResetpwdCompletedComponent } from './layout/resetpwd-completed/resetpwd-completed.component';
import { DeleteProfileComponent } from './layout/delete-profile/delete-profile.component';
import { RegistrationGuestsComponent } from './layout/registration-guests/registration-guests.component';
import { RegistrationPendingComponent } from './layout/registration-pending/registration-pending.component';
import { SetPasswordComponent } from './layout/set-password/set-password.component';
import { PhoneMaskComponent } from './layout/phone-mask/phone-mask.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    RegistrationComponent,
    RegistrationCompletedComponent,
    AppStoreComponent,
    ResetPasswordComponent,
    ResetpwdCompletedComponent,
    DeleteProfileComponent,
    RegistrationGuestsComponent,
    RegistrationPendingComponent,
    SetPasswordComponent,
    PhoneMaskComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AppRoutingModule,
    HttpClientModule,
    InputMaskModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      timeOut: 10000,
      extendedTimeOut: 6000,
      positionClass:  'toast-bottom-center',
      preventDuplicates: true,
      closeButton: false,
      easeTime: 300
    }),
    SpinnerModule,
    HeaderComponent,
    ButtonModule,
    RippleModule,
    InputTextModule,
    DropdownModule,
    CalendarModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    JwtHelperService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}