import { Component, ViewEncapsulation } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, RegistrationService } from '../../_services';
import { Constants, MyToastrService } from '../../_utility';
import { RequestRegistration } from '../../_models';
import { SpinnerService } from '../../_components/spinner/spinner.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styles: ``
})
export class SetPasswordComponent {
  public form: FormGroup;
  private pattern = '^(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=[^0-9]*[0-9]).{0,}$';

  private token!: string;

  constructor(
    private fb: FormBuilder,
    private ar: ActivatedRoute,
    private toast: MyToastrService,
    private service: RegistrationService,
    private spinner: SpinnerService,
    private router: Router
  ) {
    this.form = this.fb.group(
      {
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(this.pattern),
        ]),
        repeatpassword: new FormControl('', [Validators.required]),
      },
      { validators: this.checkPasswords }
    );

    this.ar.queryParams.subscribe((params) => {
      if (!!params) {
        this.token = params['token'];
      }
    });

  }

  checkPasswords = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get('password')?.value;
    let confirmPass = group.get('repeatpassword')?.value;
    return pass === confirmPass ? null : { notSame: true };
  };

  // Visualizza o nasconde la password
  switchIconEye(elm: HTMLDivElement) {
    let elmEye: Element = elm.getElementsByClassName('eye')[0];
    let elmInput: HTMLInputElement = elm.getElementsByTagName('input')[0];
    elmInput.type = elmInput.type == 'password' ? 'text' : 'password';
    if (elmEye.classList.contains('pi-eye')) {
      elmEye.classList.remove('pi-eye');
      elmEye.classList.add('pi-eye-slash');
    } else {
      elmEye.classList.remove('pi-eye-slash');
      elmEye.classList.add('pi-eye');
    }
  }

  async onSubmit() {
    if (this.form.invalid) {
      // console.log(this.form);

      if (!!this.form.getError('notSame') && this.form.getError('notSame')) {
        this.form.controls['repeatpassword'].markAsTouched();
        this.form.controls['repeatpassword'].markAsDirty();
        this.form.controls['repeatpassword'].setErrors({'incorrect': true});
        this.toast.ERROR(Constants.TITLE_ERROR, 'pwd_not_match');
      }

      for (const [key, value] of Object.entries(this.form.controls)) {
        // console.log(`${key}: ${value}`);
        if (value.status === 'INVALID') {
          this.form.controls[key].markAsTouched();
          this.form.controls[key].markAsDirty();
          if (key === 'password') {
            this.toast.ERROR(Constants.TITLE_ERROR, 'pwd_error');
          }
        }
      }
      return;
    }
    
    if (!this.token) {
      this.toast.ERROR(Constants.TITLE_ERROR, 'Il token non è presente');
      return;
    }

    let obj: RequestRegistration = new RequestRegistration();
    obj.password = this.form.value.repeatpassword;

    this.spinner.show();
    this.service.UserRegistration(this.token, obj).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        this.router.navigate(
          Constants.Routing.REGISTRATION_COMPLETED.routerLink
        );
      },
      error: (err) => {
        this.spinner.hide();
      },
    });

  }
}
